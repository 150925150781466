import React, { useState } from 'react';
import './SearchBox.css';  // Import the CSS file

const SearchBox = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSearch(query);
    };

    return (
        <form className="search-box" onSubmit={handleSubmit}>
            <input 
                type="text" 
                value={query} 
                onChange={(e) => setQuery(e.target.value)} 
                placeholder="અંગ્રેજી અથવા ગુજરાતીમાં Topic લખો. e.g.- વારસાઈ બાબત or Related to inheritance" 
                className="search-input"
            />
            <button type="submit" className="search-button">Search</button>
        </form>
    );
};

export default SearchBox;
