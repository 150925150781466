import React from 'react';
import './PDFThumbnail.css';  // Import the CSS file

const PDFThumbnail = ({ url, imageUrl, topic, date }) => (
    <a href={url} target="_blank" rel="noopener noreferrer" className="pdf-thumbnail">
        <img src={imageUrl} alt="PDF Thumbnail" className="thumbnail-image" />
        <p className="thumbnail-topic">{topic}</p>
        <p className="thumbnail-date">{date}</p>
    </a>
);

export default PDFThumbnail;
