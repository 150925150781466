import React, { useState, useEffect, useRef } from 'react';
import SearchBox from './components/SearchBox';
import PDFThumbnail from './components/PDFThumbnail';
import axios from 'axios';
import './App.css';

const App = () => {
    const [standardResults, setStandardResults] = useState([]);
    const [visibleStandardResults, setVisibleStandardResults] = useState(10);
    const [tagResults, setTagResults] = useState([]);
    const [visibleTagResults, setVisibleTagResults] = useState(10);
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [showGetMoreButton, setShowGetMoreButton] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
    const [toDropdownOpen, setToDropdownOpen] = useState(false);
    const [error, setError] = useState('');
    const [tharavNumbers, setTharavNumbers] = useState([]);
    const [selectedTharavNumber, setSelectedTharavNumber] = useState('');
    const [tharavDropdownOpen, setTharavDropdownOpen] = useState(false);
    const [tharavSearchInput, setTharavSearchInput] = useState('');
    const [tharavResults, setTharavResults] = useState([]);
    const [visibleTharavResults, setVisibleTharavResults] = useState(10);
    const [showGetMoreTharavButton, setShowGetMoreTharavButton] = useState(false);

    const tagDropdownRef = useRef(null);
    const tharavDropdownRef = useRef(null);
    const fromDropdownRef = useRef(null);
    const toDropdownRef = useRef(null);

    const baseUrl = "https://api.gujaratrevenue.com";

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(baseUrl + '/tags');
                setTags(response.data);
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        };

        const fetchTharavNumbers = async () => {
            try {
                const response = await axios.get(baseUrl + '/tharav_numbers');
                setTharavNumbers(response.data);
            } catch (error) {
                console.error('Error fetching Tharav numbers:', error);
            }
        };

        fetchTags();
        fetchTharavNumbers();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tagDropdownRef.current && !tagDropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
            if (tharavDropdownRef.current && !tharavDropdownRef.current.contains(event.target)) {
                setTharavDropdownOpen(false);
            }
            if (fromDropdownRef.current && !fromDropdownRef.current.contains(event.target)) {
                setFromDropdownOpen(false);
            }
            if (toDropdownRef.current && !toDropdownRef.current.contains(event.target)) {
                setToDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = async (searchQuery) => {
        setSearchInProgress(true);
        try {
            const response = await axios.post(baseUrl + '/search', { query: searchQuery });
            setStandardResults(response.data);
            setVisibleStandardResults(10);
        } catch (error) {
            console.error('Error during search:', error);
        } finally {
            setSearchInProgress(false);
        }
    };

    const handleTagSearch = async (tag, fromYear, toYear, offset = 0) => {
        try {
            const response = await axios.post(baseUrl + '/search_by_tags', { tags: [tag], fromYear, toYear, offset });
            if (offset === 0) {
                setTagResults(response.data);
                setVisibleTagResults(10);
                setShowGetMoreButton(response.data.length === 10);
            } else {
                setTagResults(prevTagResults => [...prevTagResults, ...response.data]);
                setVisibleTagResults(prevVisibleResults => prevVisibleResults + response.data.length);
                setShowGetMoreButton(response.data.length === 10);
            }
        } catch (error) {
            console.error('Error during search:', error);
        }
    };

    const handleTharavSearch = async (tharavNumber, offset = 0) => {
        try {
            const response = await axios.post(baseUrl + '/search_by_tharav_number', { tharavNumber, offset });
            if (offset === 0) {
                setTharavResults(response.data);
                setVisibleTharavResults(10);
                setShowGetMoreTharavButton(response.data.length === 10);
            } else {
                setTharavResults(prevTharavResults => [...prevTharavResults, ...response.data]);
                setVisibleTharavResults(prevVisibleResults => prevVisibleResults + response.data.length);
                setShowGetMoreTharavButton(response.data.length === 10);
            }
        } catch (error) {
            console.error('Error during search:', error);
        }
    };

    const handleTagChange = (tag) => {
        setSelectedTag(tag);
        setDropdownOpen(false);
        handleTagSearch(tag, fromDate, toDate);
    };

    const handleTharavChange = (tharavNumber) => {
        setSelectedTharavNumber(tharavNumber);
        setTharavDropdownOpen(false);
        handleTharavSearch(tharavNumber);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleTharavDropdown = () => {
        setTharavDropdownOpen(!tharavDropdownOpen);
    };

    const toggleFromDropdown = () => {
        setFromDropdownOpen(!fromDropdownOpen);
    };

    const toggleToDropdown = () => {
        setToDropdownOpen(!toDropdownOpen);
    };

    const handleFromDateChange = (year) => {
        setFromDate(year);
        setFromDropdownOpen(false);
        if (selectedTag && toDate) {
            if (year > toDate) {
                setError("'To Date' should be greater than or equal to 'From Date'");
            } else {
                setError('');
                handleTagSearch(selectedTag, year, toDate);
            }
        }
    };

    const handleToDateChange = (year) => {
        setToDate(year);
        setToDropdownOpen(false);
        if (selectedTag && fromDate) {
            if (year < fromDate) {
                setError('To Date should be greater than or equal to From Date');
            } else {
                setError('');
                handleTagSearch(selectedTag, fromDate, year);
            }
        }
    };

    const getMoreTagResults = () => {
        handleTagSearch(selectedTag, fromDate, toDate, visibleTagResults);
    };

    const getMoreTharavResults = () => {
        handleTharavSearch(selectedTharavNumber, visibleTharavResults);
    };

    const yearOptions = Array.from({ length: 124 }, (_, i) => 1900 + i).concat(2024);

    const filteredYearOptions = yearOptions.filter(year => !fromDate || year >= fromDate);

    return (
        <div className="App">
            <header className="header">
                <h1>મહેસુલ વિભાગ</h1>
                <h2 className="sub-header">ઠરાવ-પરિપત્ર-જાહેરનામા</h2>
                <p className="initiative-text">Initiative by: Assistant Collector, Suigam</p>
                <a href="https://gujaratrevenuemap.com/" target="_blank" className="btn btn-primary map-btn" rel="noreferrer">જમીનનો નકશો-વિગતો</a>
            </header>
            <div className="search-container">
                <div className="search-section">
                    <h2>વિષય લખી સર્ચ કરો</h2>
                    <SearchBox onSearch={handleSearch} />
                    {searchInProgress && (
                        <div>
                            <p className="search-status">AI Search in progress... It might take a few seconds.</p>
                            <p className="search-status">Only the top few results are usually relevant.</p>
                            <div className="spinner"></div>
                        </div>
                    )}
                    <div className="results">
                        {standardResults.slice(0, visibleStandardResults).map((result, index) => (
                            <PDFThumbnail 
                                key={index}
                                url={result.URL}
                                imageUrl={result.ImageURL}
                                topic={result.Topic}
                                date={result.Date}
                            />
                        ))}
                    </div>
                    {visibleStandardResults < standardResults.length && (
                        <div className="show-more-container">
                            <button className="show-more-button" onClick={() => setVisibleStandardResults(prev => prev + 10)}>Show more</button>
                        </div>
                    )}
                </div>
                <div className="search-section">
                    <h2>વિષય પસંદ કરી સર્ચ કરો</h2>
                    <div className="dropdown-container" ref={tagDropdownRef}>
                        <button className="dropdown-button" onClick={toggleDropdown}>
                            {selectedTag || 'Select Topic'}
                        </button>
                        {dropdownOpen && (
                            <div className="dropdown-menu">
                                {tags.map((tag, index) => (
                                    <div
                                        key={index}
                                        className="dropdown-item"
                                        onClick={() => handleTagChange(tag)}
                                    >
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {selectedTag && (
                        <div className="date-range-container">
                            <div className="dropdown-container" ref={fromDropdownRef}>
                                <button className="dropdown-button small-button" onClick={toggleFromDropdown}>
                                    {fromDate || 'From Date'}
                                </button>
                                {fromDropdownOpen && (
                                    <div className="dropdown-menu">
                                        {yearOptions.map((year, index) => (
                                            <div
                                                key={index}
                                                className="dropdown-item"
                                                onClick={() => handleFromDateChange(year)}
                                            >
                                                {year}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="dropdown-container" ref={toDropdownRef}>
                                <button className="dropdown-button small-button" onClick={toggleToDropdown}>
                                    {toDate || 'To Date'}
                                </button>
                                {toDropdownOpen && (
                                    <div className="dropdown-menu">
                                        {filteredYearOptions.map((year, index) => (
                                            <div
                                                key={index}
                                                className="dropdown-item"
                                                onClick={() => handleToDateChange(year)}
                                            >
                                                {year}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {error && (
                        <p className="error-message">{error}</p>
                    )}
                    <div className="results">
                        {tagResults.slice(0, visibleTagResults).map((result, index) => (
                            <PDFThumbnail 
                                key={index}
                                url={result.URL}
                                imageUrl={result.image_url}
                                topic={result.Topic}
                                date={result.Date}
                            />
                        ))}
                    </div>
                    {showGetMoreButton && (
                        <div className="show-more-container">
                            <button className="show-more-button" onClick={getMoreTagResults}>Show more</button>
                        </div>
                    )}
                </div>
                <div className="search-section">
                    <h2>GR નંબર થી સર્ચ કરો</h2>
                    <div className="dropdown-container" ref={tharavDropdownRef}>
                        <button className="dropdown-button" onClick={toggleTharavDropdown}>
                            {selectedTharavNumber || ' Type GR Number'}
                        </button>
                        {tharavDropdownOpen && (
                            <div className="dropdown-menu">
                                <input 
                                    type="text" 
                                    className="search-input" 
                                    value={tharavSearchInput} 
                                    onChange={(e) => setTharavSearchInput(e.target.value)} 
                                    placeholder="GR નંબરના આંકડા અંગ્રેજીમાં લખો"
                                />
                                {tharavNumbers.filter(number => number.includes(tharavSearchInput)).map((tharavNumber, index) => (
                                    <div
                                        key={index}
                                        className="dropdown-item"
                                        onClick={() => handleTharavChange(tharavNumber)}
                                    >
                                        {tharavNumber}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="results">
                        {tharavResults.slice(0, visibleTharavResults).map((result, index) => (
                            <PDFThumbnail 
                                key={index}
                                url={result.URL}
                                imageUrl={result.image_url}
                                topic={result.Topic}
                                date={result.Date}
                            />
                        ))}
                    </div>
                    {showGetMoreTharavButton && (
                        <div className="show-more-container">
                            <button className="show-more-button" onClick={getMoreTharavResults}>Show more</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default App;
